
.library-page {
    .com-border {
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 26px;
        .com-title {
            div img {
                margin-right: 10px;
            }
        }
    }
    .library-wrap {
        .top-banner-wrap {
            width: 100%;
            background: #cb2f3c;
            min-height: 300px;
            .top-banner-component {
                display: flex;
                justify-content: space-between;
                .left-top-banner {
                    width: 380px;
                    height: 250px;
                    margin-top: 25px;
                    padding: 18px;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 0;
                    h5 {
                        font-size: 22px;
                        line-height: 22px;
                        color: #fff;
                        margin-bottom: 12px;
                    }
                    div {
                        z-index: 10;
                        .banner-content {
                            font-size: 14px;
                            line-height: 26px;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 7;
                            -webkit-box-orient: vertical;
                            p {
                                color: #fff;
                            }
                        }
                        span {
                            font-size: 16px;
                            color: #fcbb0b;
                            float: right;
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                    .left-top-banner-bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 380px;
                        height: 250px;
                        background-image: url('../assets/img/bannerbg.png');
                        background-size: 100% 100%;
                        opacity: 0.4;
                        z-index: -1;
                    }
                }
            }
        }
        .library {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            .left-library {
                width: 860px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .info-library-wrap {
                    width: 860px;
                    height: 282px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .info-library {
                        .top-info-library {
                            line-height: 28px;
                            font-size: 16px;
                            margin-top: 12px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4; // 控制多行的行数
                            -webkit-box-orient: vertical;
                        }
                        ul.bottom-info-library {
                            display: flex;
                            flex-wrap: wrap;
                            // justify-content: space-between;
                            margin-top: 12px;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-line-clamp: 2; // 控制多行的行数
                            // -webkit-box-orient: vertical;
                            li {
                                width: 200px;
                                padding-left: 10px;
                                position: relative;
                                font-size: 16px;
                                color: #bd1a2d;
                                text-align: left;
                                box-sizing: border-box;
                                line-height: 40px;
                                cursor: pointer;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            li::before {
                                content: '';
                                position: absolute;
                                width: 4px;
                                height: 4px;
                                background: #ffac13;
                                border-radius: 50%;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
                .dynamic-library-wrap {
                    width: 860px;
                    height: 250px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .dynamic-library {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        .left-dynamic-library {
                            img {
                                width: 285px;
                                height: 160px;
                                cursor: pointer;
                            }
                        }
                        ul.right-dynamic-library {
                            width: 510px;
                            li {
                                width: 510px;
                                display: flex;
                                align-items: top;
                                justify-content: space-between;
                                height: 35px;
                                cursor: pointer;
                                p {
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    padding-left: 10px;
                                    box-sizing: border-box;
                                    flex: 1;
                                    position: relative;
                                }
                                p::before {
                                    content: '';
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffac13;
                                    position: absolute;
                                    left: 0;
                                    top: 11px;
                                }
                                span {
                                    font-size: 16px;
                                    color: #666666;
                                    width: 98px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
                .subject-library-wrap {
                    width: 418px;
                    height: 409px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .subject-library {
                        .first-subject-library {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                            .left-first-subject {
                                img {
                                    width: 140px;
                                    height: 88px;
                                    cursor: pointer;
                                }
                            }
                            .right-first-subject {
                                width: 221px;
                                h5 {
                                    width: 221px;
                                    font-size: 16px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                }
                                p {
                                    width: 221px;
                                    font-size: 14px;
                                    line-height: 21px;
                                    margin-top: 4px;
                                    color: #666;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        .list-subject-library {
                            li {
                                height: 39px;
                                display: flex;
                                align-items: center;
                                position: relative;
                                box-sizing: border-box;
                                padding-left: 10px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 5px;
                                }
                                p {
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                            }
                            li::before {
                                position: absolute;
                                content: '';
                                left: 0;
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                            }
                        }
                    }
                }
            }
            .right-library {
                width: 318px;
                .newest-resource-wrap {
                    width: 316px;
                    padding: 10px 20px 12px;
                    .com-title {
                        div:first-child {
                            img {
                                width: 28px;
                                height: 15px;
                                margin-right: 5px;
                            }
                        }
                    }
                    .newest-resource {
                        .btn-wrap {
                            margin-top: 14px;
                            margin-bottom: 14px;
                            display: flex;
                            justify-content: space-between;
                            button {
                                width: 133px;
                                height: 36px;
                                line-height: 36px;
                                text-align: center;
                                background: #f0f0f0;
                                font-size: 16px;
                                padding: 0;
                                border: none;
                            }
                            .active-wenji-book {
                                background: #bd1a2d;
                                color: #fff;
                            }
                        }
                        .content-newest-resource {
                            display: flex;
                            justify-content: space-between;
                            height: 148px;

                            .left-content-newest-resource {
                                img {
                                    width: 106px;
                                    height: 142px;
                                    cursor: pointer;
                                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                }
                            }
                            .right-content-newest-resource {
                                width: 160px;
                                h5 {
                                    width: 160px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 16px;
                                    cursor: pointer;
                                }
                                p {
                                    width: 160px;
                                    margin-top: 8px;
                                    font-size: 14px;
                                    line-height: 22px;
                                    font-size: 14px;
                                    color: #666666;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }

                .expert-ins-wrap {
                    padding: 0 20px;
                    .title-expert-ins {
                        display: flex;
                        .left-title-expert-ins {
                            img {
                                width: 28px;
                                height: 20px;
                            }
                            p {
                            }
                        }
                        .right-title-expert-ins {
                            img {
                                width: 21px;
                                height: 21px;
                            }
                            p {
                            }
                        }
                        .expert-ins-title {
                            display: flex;
                            height: 47px;
                            align-items: center;
                            width: 138px;
                            border-bottom: 2px solid #dedede;
                            background: #f0f0f0;
                            justify-content: center;
                            margin-bottom: 20px;
                            cursor: pointer;
                            img {
                            }
                            p {
                                width: 48px;
                                color: #666;
                                margin-left: 6px;
                                font-size: 24px;
                                line-height: 47px;
                                box-sizing: border-box;
                            }
                        }
                        .active-expert-ins-title {
                            background: #fff;
                            p {
                                color: #000;
                                border-bottom: 3px solid #bd1a2d;
                            }
                        }
                    }
                    .expert-ins {
                        height: 178px;
                    }
                }
            }
        }
    }
}

.open-access-wrap {
    // padding: 0 20px;
    // width: 316px;
    height: 380px;
    padding: 10px 20px;
    box-sizing: border-box;
    // box-sizing: border-box;
    // background: #fefefe;
    // border: 1px solid #f5f5f5;
    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    // margin: 24px 0;
    .com-title {
        div {
            img.title-img {
                width: 28px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }
    .open-access {
        padding-top: 20px;
        p {
            width: 276px;

            padding-left: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            position: relative;
            height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            img {
                width: 19px;
                height: 19px;
                margin-right: 5px;
            }
            span {
                display: block;
                font-size: 16px;
                color: #333;
                width: 242px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        p::before {
            content: '';
            position: absolute;
            left: 0;
            width: 4px;
            height: 4px;
            background: #ffac13;
            border-radius: 50%;
        }
    }
}
.new-preprint-wrap {
    height: 364px;
    padding: 10px 20px;
    box-sizing: border-box;
    .com-title {
        div {
            img.title-img {
                width: 29px;
                height: 29px;
                margin-right: 10px;
            }
            span {
                a {
                    color: #404040;
                }
            }
        }
    }
    .new-preprint {
        margin-top: 20px;
        .com-p {
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
            cursor: pointer;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.new-special-wrap {
    // padding: 0 20px;
    // width: 316px;
    // box-sizing: border-box;
    // background: #fefefe;
    // border: 1px solid #f5f5f5;
    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    // margin: 24px 0;
    padding: 10px 20px;
    .new-special {
        .top-new-special {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            li {
                width: 134px;
                height: 104px;
                margin-bottom: 15px;
                img {
                    display: block;
                    width: 100%;
                    height: 87px;
                    cursor: pointer;
                }
                p {
                    line-height: 17px;
                    height: 17px;
                    width: 134px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: #d1d1d1;
                    font-size: 12px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .com-p {
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
            cursor: pointer;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.new-media-wrap {
    padding: 10px 20px;
    .new-media {
        margin-top: 20px;
        position: relative;
        ul {
            display: flex;
            justify-content: space-between;
            li {
                width: 127px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: #efefef;
                color: #000000;
                cursor: pointer;
            }
            .active-new-media {
                color: #fff;
                background: #bd192d;
            }
        }
        img {
            cursor: pointer;
        }
        h6 {
            position: absolute;
            left: 0;
            top: 183px;
            width: 281px;
            font-size: 14px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            background: rgba(2, 2, 2, 0.5);
            padding-left: 10px;
            box-sizing: border-box;
            cursor: pointer;
        }
        .com-p {
            cursor: pointer;
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }

        img {
            width: 281px;
            height: 175px;
            margin: 13px auto 16px;
        }
    }
}
